import React, { useState } from "react";
//import axios from "axios";
import "./App.css"; 
import logo from './images/logo.jpeg';

function App() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [liberado, setLiberado] = useState("false");

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // const response = await axios.get("http://localhost:3001/users", {
      //   params: { username, password },
      // });

      // const user = response.data.find(
      //   (user) => user.username === username && user.password === password
      // );
      if(username === 'Admin' && password === 'B1Admin'){
        setLiberado("true");
      }
      else{
        setLiberado("false");
      }

      if (liberado === "true") {
        
        window.location.href = "https://ficha.visioncare.com.br/";
      } else {
        setError("Invalid username or password");
      }
    } catch (err) {
      console.error(err);
      setError("Failed to login");
    }
  };

  return (
    <div className="login-container">
      <div className="login-image">
     
        <img
          src= {logo}
          alt="Login"
        />
      </div>
      <div className="login-form">
        <h2>Entre na sua conta</h2>
        <form onSubmit={handleLogin}>
          <div>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit">Log In</button>
        </form>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
}

export default App;
